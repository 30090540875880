import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { BodyMedium } from '@src/components/styled/Typography';
import DatePeriodMenu, { GeneralPortfolioInformationPeriodsPreset } from '@shared/ui/menus/DatePeriodMenu';
import { AssetsCurve, CopiersCurve, MarginCurve, ProfitCurve, } from '@entities/portfolio';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { PROFIT_DATA, MANAGER_ASSETS_DATA, MARGIN_DATA, COPIERS_DATA, } from './mocks';
import { getAbsValues, getCopiersValues, getDefaultGraphRange, getManagerAssetsValues, getMarginValues, } from './utils';
const InfoGraphsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    boxSizing: 'border-box',
    background: props.theme.palette.text.disabled,
    padding: props.theme.spacing_sizes.l,
    borderRadius: 12,
    gap: props.theme.spacing_sizes.l,
}));
const PeriodSelectContainer = styled.div();
const GraphsTabsContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const EmptyContainer = styled.div(() => ({
    height: 40,
}));
var PortfolioInfoGraph;
(function (PortfolioInfoGraph) {
    PortfolioInfoGraph["Profit"] = "PROFIT";
    PortfolioInfoGraph["ManagerAssets"] = "MANAGER_ASSETS";
    PortfolioInfoGraph["FollowersAssets"] = "FOLLOWERS_ASSETS";
    PortfolioInfoGraph["Margin"] = "MARGIN";
    PortfolioInfoGraph["CopiersNumber"] = "COPIERS_NUMBER";
    PortfolioInfoGraph["Risk"] = "RISK";
})(PortfolioInfoGraph || (PortfolioInfoGraph = {}));
const PortfolioInfoGraphs = ({ isLoading = false, }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(PortfolioInfoGraph.Profit);
    const [activeFilter, setActiveFilter] = useState(GeneralPortfolioInformationPeriodsPreset[0]);
    const handleFilterChange = (p) => {
        setActiveFilter(p);
    };
    const { assetsData, userProfitData, marginData, copiersData, } = useMemo(() => {
        if (activeFilter.type === 'day') {
            switch (activeFilter.count) {
                case 180:
                    return {
                        userProfitData: {
                            data: getAbsValues(PROFIT_DATA.slice(0, 180)),
                            defaultRange: getDefaultGraphRange(180),
                        },
                        assetsData: {
                            data: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 180)),
                            defaultRange: getDefaultGraphRange(180),
                        },
                        marginData: {
                            data: getMarginValues(MARGIN_DATA.slice(0, 180)),
                            defaultRange: getDefaultGraphRange(180),
                        },
                        copiersData: {
                            data: getCopiersValues(COPIERS_DATA.slice(0, 180)),
                            defaultRange: getDefaultGraphRange(180),
                        },
                    };
                case 90:
                    return {
                        userProfitData: {
                            data: getAbsValues(PROFIT_DATA.slice(0, 90)),
                            defaultRange: getDefaultGraphRange(90),
                        },
                        assetsData: {
                            data: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 90)),
                            defaultRange: getDefaultGraphRange(90),
                        },
                        marginData: {
                            data: getMarginValues(MARGIN_DATA.slice(0, 90)),
                            defaultRange: getDefaultGraphRange(90),
                        },
                        copiersData: {
                            data: getCopiersValues(COPIERS_DATA.slice(0, 90)),
                            defaultRange: getDefaultGraphRange(90),
                        },
                    };
                case 30:
                    return {
                        userProfitData: {
                            data: getAbsValues(PROFIT_DATA.slice(0, 30)),
                            defaultRange: getDefaultGraphRange(30),
                        },
                        assetsData: {
                            data: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 30)),
                            defaultRange: getDefaultGraphRange(30),
                        },
                        marginData: {
                            data: getMarginValues(MARGIN_DATA.slice(0, 30)),
                            defaultRange: getDefaultGraphRange(30),
                        },
                        copiersData: {
                            data: getCopiersValues(COPIERS_DATA.slice(0, 30)),
                            defaultRange: getDefaultGraphRange(30),
                        },
                    };
                case 7:
                    return {
                        userProfitData: {
                            data: getAbsValues(PROFIT_DATA).slice(0, 7),
                            defaultRange: getDefaultGraphRange(7),
                        },
                        assetsData: {
                            data: getManagerAssetsValues(MANAGER_ASSETS_DATA).slice(0, 7),
                            defaultRange: getDefaultGraphRange(7),
                        },
                        marginData: {
                            data: getMarginValues(MARGIN_DATA).slice(0, 7),
                            defaultRange: getDefaultGraphRange(7),
                        },
                        copiersData: {
                            data: getCopiersValues(COPIERS_DATA).slice(0, 7),
                            defaultRange: getDefaultGraphRange(7),
                        },
                    };
                default:
            }
        }
        return {
            userProfitData: {
                data: getAbsValues(PROFIT_DATA),
                defaultRange: getDefaultGraphRange(365),
            },
            assetsData: {
                data: getManagerAssetsValues(MANAGER_ASSETS_DATA),
                defaultRange: getDefaultGraphRange(365),
            },
            marginData: {
                data: getMarginValues(MARGIN_DATA),
                defaultRange: getDefaultGraphRange(365),
            },
            copiersData: {
                data: getCopiersValues(COPIERS_DATA),
                defaultRange: getDefaultGraphRange(365),
            },
        };
    }, [activeFilter]);
    const TabComponent = useMemo(() => {
        switch (activeTab) {
            case PortfolioInfoGraph.Profit:
                return (_jsx(ProfitCurve, { data: [], defaultRange: userProfitData.defaultRange, isLoading: isLoading }));
            case PortfolioInfoGraph.ManagerAssets:
                return (_jsx(AssetsCurve, { data: [], defaultRange: assetsData.defaultRange, assetName: 'USDT', isLoading: isLoading }));
            case PortfolioInfoGraph.FollowersAssets:
                return (_jsx(AssetsCurve, { data: [], defaultRange: assetsData.defaultRange, assetName: 'USDT', isLoading: isLoading }));
            case PortfolioInfoGraph.Margin:
                return (_jsx(MarginCurve, { data: [], defaultRange: marginData.defaultRange, isLoading: isLoading }));
            case PortfolioInfoGraph.CopiersNumber:
                return (_jsx(CopiersCurve, { data: [], defaultRange: copiersData.defaultRange, isLoading: isLoading }));
            case PortfolioInfoGraph.Risk:
            default:
                return null;
        }
    }, [activeTab, assetsData, userProfitData, isLoading, copiersData, marginData]);
    return (_jsxs(InfoGraphsContainer, { children: [_jsx(GraphsTabsContainer, { children: isLoading ?
                    _jsx(EmptyContainer, {}) :
                    _jsxs(_Fragment, { children: [_jsx(Tabs, { activeTab: activeTab, onChange: setActiveTab, variant: 'chart-tabs', tabs: [
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.profit', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.Profit,
                                    },
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.manager_assets', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.ManagerAssets,
                                    },
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.followers_assets', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.FollowersAssets,
                                    },
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.margin', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.Margin,
                                    },
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.copiers_number', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.CopiersNumber,
                                    },
                                    {
                                        label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.risks', { ns: 'common' }) })),
                                        value: PortfolioInfoGraph.Risk,
                                    },
                                ] }), _jsx(PeriodSelectContainer, { children: _jsx(DatePeriodMenu, { periods: GeneralPortfolioInformationPeriodsPreset, currentPeriod: activeFilter, onPeriodChange: handleFilterChange }) })] }) }), TabComponent] }));
};
export default PortfolioInfoGraphs;
